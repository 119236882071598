<template>
  <div>
    <div class="foot_top_container">
      <div class="foot_top">
        <!-- <div class="links">
          <div class="links_txt">友情链接</div>
          <img src="@/assets/link.png" />
        </div> -->
      </div>
    </div>
    <div class="foot_black">
      <div class="fcontainer">
        <div v-if="showSearch" class="f_search">
          <div class="f_search_row">
            <input v-model="searchStr" placeholder="请输入产品名称" class="f_search_row_1" type="text" />
            <div @click="toSearch" class="f_search_row_2">搜索</div>
          </div>
        </div>
        <div class="f_l">
          <div class="f_l_row_1">
            <img src="@/assets/f_phone.png" />
            400-075-8858
          </div>
          <div class="f_l_row_2">
            <img src="@/assets/f_email.png" />
            邮箱：163go126@163.com
          </div>
          <div class="f_l_row_2">
            <img src="@/assets/f_address.png" />
            地址：徐州高新区中国安全谷A6栋
          </div>
        </div>
        <div class="f_c">
          <div class="f_c_column">
            <div class="f_c_column_1">产品中心</div>
            <div v-for="(i, index) in categoryData" :key="index" @click="toProduct(index)" class="f_c_column_2">{{ i.category }}</div>
            <!-- <div @click="toProduct(0)" class="f_c_column_2">系统方案</div>
            <div @click="toProduct(1)" class="f_c_column_2">控制器</div>
            <div @click="toProduct(2)" class="f_c_column_2">传感器</div>
            <div @click="toProduct(3)" class="f_c_column_2">气动工具</div> -->
            <div @click="toProduct(4)" class="f_c_column_2">更多产品</div>
          </div>
          <div class="f_c_column">
            <div class="f_c_column_1">关于我们</div>
            <div @click="toAbout" class="f_c_column_2">企业资质</div>
            <div @click="toContact" class="f_c_column_2">免责声明</div>
          </div>
          <div class="f_c_column">
            <div class="f_c_column_1">公司新闻</div>
            <div @click="toNews(1)" class="f_c_column_2">公司新闻</div>
            <div @click="toNews(2)" class="f_c_column_2">行业动态</div>
            <div @click="toNews(3)" class="f_c_column_2">常见问题</div>
          </div>
        </div>
        <div class="f_r">
          <div class="f_r_column">
            <div class="f_r_column_code">
              <img src="@/assets/code1.png" />
            </div>
            <div class="f_r_column_name">公众号</div>
          </div>
          <div class="f_r_column">
            <div class="f_r_column_code">
              <img src="@/assets/code2.png" />
            </div>
            <div class="f_r_column_name">企业微信</div>
          </div>
          <div class="f_r_column">
            <div class="f_r_column_code">
              <img src="@/assets/code3.png" />
            </div>
            <div class="f_r_column_name">淘宝店铺</div>
          </div>
        </div>
      </div>
      <div class="foot_copy">Copyright © 2019-2024 江苏追光智能科技有限公司 版权所有 Powered by EyouCms 备案号：苏ICP备20027758号-1</div>
    </div>
  </div>
</template>

<script>
import { GET_PRODUCT_CATEGORY } from '@/api'
export default {
  name: 'foot',
  data() {
    return {
      categoryData: [],
      searchStr: '',
      showSearch: true
    }
  },
  watch: {
    $route: {
      handler: function (val) {
        if (val.name == 'home') {
          this.showSearch = true
        } else {
          this.showSearch = false
        }
      },
      deep: true
    }
  },
  created() {
    this.getModelList()
  },
  methods: {
    toSearch() {
      this.$router.push({ path: '/product', query: { index: -1, searchStr: this.searchStr } })
    },
    toNews(index) {
      this.$router.push({ path: '/news', query: { index: index } })
    },
    toProduct(index) {
      this.$router.push({ path: '/product', query: { index: index } })
    },
    toAbout() {
      this.$router.push({ path: '/about', query: {} })
    },
    toContact() {
      this.$router.push({ path: '/contact', query: { index: 2 } })
    },
    getModelList() {
      this.$http({
        url: GET_PRODUCT_CATEGORY,
        params: {
          pageSize: this.pageSize,
          page: this.page
        },
        method: 'get'
      })
        .then((res) => {
          if (res.code == 200) {
            this.categoryData = res.result.list
          }
        })
        .catch((error) => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.foot_top_container {
  margin-bottom: 94px;

  .foot_top {
    width: 1200px;
    margin: 0 auto;
    padding-top: 16px;
    padding-bottom: 12px;

    .links {
      position: relative;
      width: 102px;
      height: 36px;
      display: flex;
      justify-content: center;

      .links_txt {
        position: relative;
        top: -7px;
        z-index: 2;
        width: 72px;
        height: 30px;
        line-height: 30px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.9);
        background-color: #fff;
        text-align: center;
      }

      img {
        position: absolute;
        bottom: 2px;
        width: 100px;
        height: 28px;
        z-index: 1;
      }
    }
  }
}

.foot_black {
  background: #161502;
  // display: flex;
  flex-wrap: wrap;
  padding-top: 112px;
  padding-bottom: 52px;
  justify-content: center;

  .fcontainer {
    width: 1200px;
    margin: 0 auto;
    height: 200px;
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-wrap: nowrap;

    .f_l {
      width: 279px;
      height: 100%;

      .f_l_row_1 {
        line-height: 44px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        font-size: 33px;
        color: #fff;
        justify-content: space-between;

        img {
          width: 40px;
          height: 40px;
        }
      }

      .f_l_row_2 {
        margin-top: 8px;
        height: 33px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        color: #fff;
        font-size: 16px;

        img {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
      }
    }

    .f_c {
      width: 366px;
      margin: 0 64px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .f_c_column {
        width: 90px;

        .f_c_column_1 {
          font-size: 20px;
          color: #fff;
          font-weight: bold;
          line-height: 32px;
          margin-bottom: 24px;
        }

        .f_c_column_2 {
          font-size: 16px;
          line-height: 24px;
          color: rgba(255, 255, 255, 0.55);
          margin-bottom: 4px;
          cursor: pointer;
        }
      }
    }

    .f_r {
      width: 427px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      .f_r_column_code {
        width: 126px;
        height: 126px;

        img {
          width: 126px;
          height: 126px;
        }
      }

      .f_r_column_name {
        margin-top: 8px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        line-height: 24px;
      }
    }

    .f_search {
      position: absolute;
      top: -168px;
      right: 115px;
      width: 468px;
      height: 96px;
      background-color: #0049f0;
      display: flex;
      align-items: center;

      .f_search_row {
        width: 100%;
        height: 48px;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        padding: 0 24px;
        font-size: 16px;

        .f_search_row_1 {
          width: 300px;
          height: 48px;
          border-radius: 2px;
          background: #fff;
          border: none;
          text-indent: 16px;
          outline: none;
        }

        .f_search_row_2 {
          width: 96px;
          height: 48px;
          border-radius: 2px;
          background: #1e222b;
          text-align: center;
          line-height: 48px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }

  .foot_copy {
    padding-top: 50px;
    width: 1200px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    font-size: 14px;
  }
}
</style>
